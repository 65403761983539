import React from 'react';

const Review = () => {
  return (
    <div className="relative bg-cover bg-center h-80 flex items-center justify-center text-white bg-review" style={{minHeight: "500px"}}>
      <div className="bg-blue-900 bg-opacity-70 p-8 rounded-lg text-center ms:w-full md:w-1/2">
        <div className="text-3xl font-bold mb-4">Best tour agency, excellent vacations</div>
        <p className="text-sm italic mb-6">
          My testimonial is from a customer or client. They may be an unknown person to the reader, 
          you need to be sure there isn't anything embarrassing hidden in the middle of text.
        </p>
        <div className="flex items-center justify-center space-x-4">
          <img
            className="w-12 h-12 rounded-full object-cover"
            src="https://randomuser.me/api/portraits/women/44.jpg"
            alt="Martha Smith"
          />
          <div>
            <h3 className="font-semibold">Martha Smith</h3>
            <p className="text-yellow-300">Solo Traveller</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
