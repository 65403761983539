import { ToastContainer } from "react-toastify"
import Footer from "./Footer/Footer"
import Header from "./Header/Header"

const UserLayout = ({children})=>{
    return (<div>
        <ToastContainer/>
        <Header/>
        {children}
        <Footer/>
    </div>)
}
export default UserLayout