import React from "react";
import Slider from "react-slick";
import { FaFacebookF, FaTwitter, FaInstagram, FaTiktok } from "react-icons/fa"; // Social media icons
import fs1 from "../../../../asset/fs1.jpg";
import fs2 from "../../../../asset/fs1_1.jpg";
import fs3 from "../../../../asset/fs1_2.jpg";
const FullWidthCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true, // Hides next/previous arrows
    autoplay: true,
    autoplaySpeed: 4000
  };
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3, // Show 3 cards at a time
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2, // Show 2 cards on medium screens
  //         slidesToScroll: 1,
  //         infinite: true,
  //         dots: true,
  //       },
  //     },
  //     {
  //       breakpoint: 640,
  //       settings: {
  //         slidesToShow: 1, // Show 1 card on small screens
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  const slides = [
    {
      id: 1,
      image: fs1, // Replace with actual image paths
      caption: "A MORE REWARDING WAY TO TRAVEL",
    },
    {
      id: 2,
      image: fs2,
      caption: "EXPLORE THE WORLD",
    },
    {
      id: 3,
      image: fs3,
      caption: "EXPLORE THE WORLD",
    },
  ];

  return (
    <div className="relative full-carousel">
      <Slider {...settings}>
        {slides.map((slide) => (
          <div key={slide.id} className="relative w-full h-screen">
            {/* Background Image */}
            <img
              src={slide.image}
              alt={slide.caption}
              className="w-full h-full object-cover"
            />
            {/* Caption */}
            <div className="absolute inset-0 flex flex-col justify-center items-center text-white text-center">
              <h2 className="text-4xl md:text-6xl font-bold mb-4">
                {slide.caption}
              </h2>
            </div>
          </div>
        ))}
      </Slider>

      {/* Social Media Icons */}
      <div className="absolute top-1/2 -translate-y-1/2 right-10 space-y-6">
        <a href="https://www.facebook.com/profile.php?id=100009678076941" target="_blank" className="text-white text-2xl hover:text-blue-500">
          <FaFacebookF />
        </a>
        <a href="" target="_blank" className="text-white text-2xl hover:text-blue-400">
          <FaTwitter />
        </a>
        <a href="" target="_blank" className="text-white text-2xl hover:text-pink-500">
          <FaInstagram />
        </a>
        <a href="" target="_blank" className="text-white text-2xl hover:text-black">
          <FaTiktok />
        </a>
      </div>

      {/* <div className="absolute top-1/2 -translate-y-1/2 left-10 space-y-6">
        <a href="#" className="text-white text-2xl hover:text-blue-500">
          <FaFacebookF />
        </a>
        <a href="#" className="text-white text-2xl hover:text-blue-400">
          <FaTwitter />
        </a>
        <a href="#" className="text-white text-2xl hover:text-pink-500">
          <FaInstagram />
        </a>
        <a href="#" className="text-white text-2xl hover:text-black">
          <FaTiktok />
        </a>
      </div> */}

      {/* Description boxes */}
      <div className="absolute bottom-0 left-0 right-0 p-4 bg-opacity-50 bg-white text-black">
        <div className="container mx-auto flex justify-around">
          <div className="text-center">
            <p className="font-bold text-lg">Scuba Diving</p>
            <p>Adrenaline-pumping excursions for the experienced.</p>
          </div>
          <div className="text-center">
            <p className="font-bold text-lg">ATV</p>
            <p>Start your engines and get ready for an adventure.</p>
          </div>
          <div className="text-center">
            <p className="font-bold text-lg">Paragliding</p>
            <p>Wild landscapes and rough rivers await your adventure.</p>
          </div>
          <div className="text-center">
            <p className="font-bold text-lg">Cycling</p>
            <p>Thrilling off-road experiences on scenic paths.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullWidthCarousel;
