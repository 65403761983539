import React from 'react';
import image from "../../../../asset/cycling.jpeg"
const teamMembers = [
  {
    name: 'Julia Jones',
    role: 'Marketing',
    image: image, // Replace with actual image URL
    social: {
      facebook: '#',
      twitter: '#',
      linkedin: '#',
      dribbble: '#',
    },
  },
  {
    name: 'Carlos Cimino',
    role: 'Marketing',
    image: image, // Replace with actual image URL
    social: {
      facebook: '#',
      twitter: '#',
      linkedin: '#',
      dribbble: '#',
    },
  },
  {
    name: 'Ricardo Anje',
    role: 'Tour Operator',
    image: image, // Replace with actual image URL
    social: {
      facebook: '#',
      twitter: '#',
      linkedin: '#',
      dribbble: '#',
    },
  },
];

const OurTeam = () => {
  return (
    <div className="bg-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Our Team</h2>
        <p className="text-gray-600 mb-12">
          We have a team of professionals, dedicated to providing excellent service to ensure the enjoyment of your vacation.
        </p>

        {/* Team grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {teamMembers.map((member) => (
            <div key={member.name} className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center">
              <img
                className="w-40 h-40 rounded-full object-cover mb-4"
                src={member.image}
                alt={member.name}
              />
              <h3 className="text-xl font-semibold text-gray-900">{member.name}</h3>
              <p className="text-gray-600">{member.role}</p>

              {/* Social media icons */}
              <div className="flex space-x-4 mt-4">
                <a href={member.social.facebook} className="text-teal-500 hover:text-teal-600">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href={member.social.twitter} className="text-teal-500 hover:text-teal-600">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href={member.social.linkedin} className="text-teal-500 hover:text-teal-600">
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a href={member.social.dribbble} className="text-teal-500 hover:text-teal-600">
                  <i className="fab fa-dribbble"></i>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
