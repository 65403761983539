import React, { useState } from "react";
import UserLayout from "../../Components/Layout/UserLayout/UserLayout";
import { ToastContainer, toast } from "react-toastify";
import ContactUsForm from "../../Components/UI/ContactUsForm/ContactUsForm";

const ContactUs = () => {
  return (
    <UserLayout>
    <div className="">
        <div className="bg-gray-100 flex" style={{minHeight:"200px"}}>
          <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center" style={{width: "80%", padding: "40px"}}>
          <div className="text-center md:text-left mb-6 md:mb-0">
            <div className="flex items-center justify-center md:justify-start">
              <svg
                className="w-6 h-6 mr-2 text-blue-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 11v5a2 2 0 001 1.73l5-2.86a2 2 0 00.5-.84V11a2 2 0 00-.5-.84l-5-2.86A2 2 0 008 8v3zm0 0l5 2.86V11m7 8a10 10 0 11-14 0 10 10 0 0114 0z"
                />
              </svg>
              <p>99/1, Borobazar, Amborkhana, Sylhet</p>
            </div>
          </div>
          <div className="text-center mb-6 md:mb-0">
            <div className="flex items-center justify-center">
              <svg
                className="w-6 h-6 mr-2 text-blue-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M16 8V6a4 4 0 10-8 0v2a2 2 0 01-2 2H6a2 2 0 000 4h12a2 2 0 000-4h-2a2 2 0 01-2-2V8z"
                />
              </svg>
              <p>+880 1307974287</p>
            </div>
          </div>
          <div className="text-center">
            <div className="flex items-center justify-center">
              <svg
                className="w-6 h-6 mr-2 text-blue-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 10a2 2 0 00-2-2H5a2 2 0 00-2 2v7a2 2 0 002 2h14a2 2 0 002-2v-7z"
                />
              </svg>
              <p>sawondeyc@gmail.com</p>
            </div>
          </div>
            </div>
        </div>
        <div className="flex justify-center align-center p-10">
        <div className="md:w-1/2">
          <h2 className="text-2xl font-bold text-center mb-6">
            Get in Touch With Us
          </h2>
          <p className="text-center text-gray-600 mb-8">
            World’s leading non-asset-based supply chain management companies,
            we design and implement industry-leading solutions. We specialize in
            intelligent and effective search and business.
          </p>
            <ContactUsForm/>
          <p className="text-center text-gray-400 text-sm mt-4">
            * These fields are required.
          </p>
        </div>
        </div>
    </div>
    </UserLayout>
  );
};

export default ContactUs;
