import React from "react";
import Slider from "react-slick";
import { AiFillStar } from "react-icons/ai"; // For star ratings
import image from "../../../../asset/cycling.jpeg";
import tribalVisit from "../../../../asset/tribal_home_visit.jpg";
import tribalActivity from "../../../../asset/tribal_activities.jpg";
import cityVisit from "../../../../asset/city_travel.jpg";

const PopularActivities = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 cards at a time
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2, // Show 2 cards on medium screens
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1, // Show 1 card on small screens
          slidesToScroll: 1,
        },
      },
    ],
  };

  const activities = [
    {
      id: 1,
      title: "Sylhet city Visit",
      image: cityVisit, // Replace with your image path
      price: "$25",
      rating: 8.5,
      reviews: 600,
      description:
        "Traveling towards the Sylhet city, rickshaw journey enjoy different street food.",
    },
    {
      id: 2,
      title: "Tribal Activity",
      image: tribalActivity,
      price: "$35",
      rating: 9.5,
      reviews: 611,
      description:
        "Whole day tribal activitis their lifestyle visit and experience.",
    },
    {
      id: 3,
      title: "Tribal Home visit",
      image: tribalVisit,
      price: "$30",
      rating: 8.8,
      reviews: 411,
      description:
        "Enjoy tribal living place and have a lunch with them.",
    }
  ];

  return (
    <div className="container mx-auto py-12">
      <h2 className="text-3xl font-bold text-center mb-6">Popular Activities</h2>
      <p className="text-center text-gray-500 mb-8">
        We offer you the most exciting activities so you can have unforgettable
        vacations.
      </p>
      <Slider {...settings}>
        {activities.map((activity) => (
          <a
            key={activity.id}
            className="p-4"
            href={`/tour/${activity.id}`}
            style={{cursor: "pointer"}}
          >
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
              {/* Image */}
              <div className="relative">
                <img
                  src={activity.image}
                  alt={activity.title}
                  className="w-full h-60 object-cover"
                />
                <span className="absolute top-4 left-4 bg-white text-black font-bold px-2 py-1 rounded-lg">
                  {activity.price}
                </span>
              </div>
              {/* Content */}
              <div className="p-4">
                <h3 className="text-xl font-semibold mb-2">{activity.title}</h3>
                <div className="flex items-center mb-2">
                  <span className="text-2xl font-bold mr-2">
                    {activity.rating}
                  </span>
                  <div className="flex">
                    {[...Array(Math.floor(activity.rating))].map((_, i) => (
                      <AiFillStar key={i} className="text-yellow-400" />
                    ))}
                  </div>
                  <span className="text-gray-500 ml-2">
                    {activity.reviews} Ratings
                  </span>
                </div>
                <p className="text-gray-600">{activity.description}</p>
              </div>
            </div>
          </a>
        ))}
      </Slider>
    </div>
  );
};

export default PopularActivities;
