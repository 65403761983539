import { useState } from "react";
import { toast } from "react-toastify";
export default function ContactUsForm(){
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    
      const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        setFormData({name: "",
        email: "",
        phone: "",
        message: "",})
        toast.success("Submitted success");
        // You can add logic for submitting the form (e.g., API call)
      };
    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <input
                  type="text"
                  name="name"
                  placeholder="*Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="w-full p-3 rounded-3xl bg-gray-100 border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div>
                <input
                  type="email"
                  name="email"
                  placeholder="*Email address"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full p-3 rounded-3xl bg-gray-100 border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
            </div>
            <div>
              <input
                type="text"
                name="phone"
                placeholder="*Please enter your phone"
                value={formData.phone}
                onChange={handleChange}
                required
                className="w-full p-3 bg-gray-100 rounded-3xl border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div>
              <textarea
                name="message"
                placeholder="*Your message"
                value={formData.message}
                onChange={handleChange}
                required
                className="w-full p-3 rounded-3xl bg-gray-100 border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 h-32"
              ></textarea>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="w-full md:w-auto px-6 py-3 bg-green-600 text-white rounded-md hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
              >
                SEND MESSAGE
              </button>
            </div>
          </form>
    )
}