import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaTiktok } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="p-8" style={{backgroundColor: "#2485B0"}}>
      <div className="container mx-auto text-center">
        {/* Logo Section */}
        <div className="text-white text-2xl font-bold mb-6">
          <span className="tracking-widest">Expolre With Sawon</span>
        </div>

        {/* Navigation Links */}
        <ul className="hidden md:flex justify-center space-x-8 text-white text-lg font-semibold mb-6">
        <li className="hover:text-gray-200 cursor-pointer"><a href='/'>HOME</a></li>
          <li className="hover:text-gray-200 cursor-pointer"><a href='/about'>ABOUT</a></li>
          <li className="hover:text-gray-200 cursor-pointer"><a href='/tours'>TOURS</a></li>
          <li className="hover:text-gray-200 cursor-pointer"><a href='/contact-us'>CONTACT</a></li>
        </ul>

        <ul className="md:hidden flex flex-col space-y-4 text-white text-lg font-semibold p-4">
          <li className="hover:text-gray-200 cursor-pointer"><a href='/'>HOME</a></li>
          <li className="hover:text-gray-200 cursor-pointer"><a href='/about'>ABOUT</a></li>
          <li className="hover:text-gray-200 cursor-pointer"><a href='/tours'>TOURS</a></li>
          <li className="hover:text-gray-200 cursor-pointer"><a href='/contact-us'>CONTACT</a></li>
        </ul>
        {/* Social Media Icons */}
        <div className="flex justify-center space-x-6 text-white text-3xl">
          <a href="https://www.facebook.com/profile.php?id=100009678076941" target="_blank" className="hover:text-gray-200">
            <FaFacebook />
          </a>
          <a href="" target="_blank" className="hover:text-gray-200">
            <FaTwitter />
          </a>
          <a href="" target="_blank" className="hover:text-gray-200">
            <FaInstagram />
          </a>
          <a href="" target="_blank" className="hover:text-gray-200">
            <FaTiktok />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
