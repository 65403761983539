import React from 'react';
import OurTeam from './Team/Team';
import UserLayout from '../../Components/Layout/UserLayout/UserLayout';
import Review from '../Home/Review/Review';
import image from "../../../asset/hills.jpeg"
const AboutUs = () => {
  return (
    <UserLayout>
        <div className="bg-gray-50 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 lg:grid-cols-2 gap-8">
            {/* Left side content */}
            <div className="flex flex-col justify-center">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
                Memories Made Together Last a Lifetime
            </h2>
            <p className="text-gray-700 font-semibold mb-2">
                Growing community by inspiring healthy is our commitment with farmers, the abundant living on the field.
            </p>
            <p className="text-gray-500 mb-6">
                Your satisfaction with your experience that your recommendation. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
            </p>

            {/* Features section */}
            <div className="space-y-4">
                <div className="flex items-start">
                <div className="flex-shrink-0">
                    <svg
                    className="w-6 h-6 text-blue-600"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 13l4 4L19 7"
                    />
                    </svg>
                </div>
                <div className="ml-3">
                    <h4 className="text-lg font-bold text-gray-900">Satisfaction Guarantee</h4>
                    <p className="text-gray-500">
                    Despite the variety of fish you could catch, no fish is more desirable than the bluefin tuna.
                    </p>
                </div>
                </div>

                <div className="flex items-start">
                <div className="flex-shrink-0">
                    <svg
                    className="w-6 h-6 text-blue-600"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 12h14M12 5l7 7-7 7"
                    />
                    </svg>
                </div>
                <div className="ml-3">
                    <h4 className="text-lg font-bold text-gray-900">Online Support</h4>
                    <p className="text-gray-500">
                    Some anglers have even battled to reel in bluefin tuna weighing trips travel a maximum.
                    </p>
                </div>
                </div>
            </div>
            </div>

            {/* Right side image */}
            <div className="flex justify-center items-center">
            <img
                src={image} // Replace with your image URL
                alt="Travel Memories"
                className="rounded-lg shadow-lg"
            />
            </div>
        </div>
        </div>
        <OurTeam/>
        <Review/>
    </UserLayout>
  );
};

export default AboutUs;
