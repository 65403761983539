
import './App.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Navigate, Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import Home from './App/Containers/Home/Home';
import ContactUs from './App/Containers/ContactUs/ContactUs';
import 'react-toastify/dist/ReactToastify.css';
import AboutUs from './App/Containers/AboutUs/AboutUs';
import Tours from './App/Containers/Tours/Tours';
import TourDetails from './App/Containers/Tours/Tour/Tour';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Navigate to="/home"/>}/>
          <Route path='/home' element={<Home/>}/>
          <Route path='/contact-us' element={<ContactUs/>}/>
          <Route path="/about" element = {<AboutUs/>}/>
          <Route path="/tours" element = {<Tours/>} />
          <Route path='/tour/:id' element={<TourDetails/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
