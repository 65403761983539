import React from 'react';
import UserLayout from '../../Components/Layout/UserLayout/UserLayout';
import image from "../../../asset/cycling.jpeg";
const toursData = [
  {
    title: 'ATV',
    price: '$130',
    rating: 8.8,
    reviews: '411 Ratings',
    description: 'Where you will receive a safety briefing about how to operate your ATV and where friendly.',
    image: image
    // image: 'https://source.unsplash.com/featured/?atv',
  },
  {
    title: 'Yacht Excursion',
    price: '$450',
    rating: 9.5,
    reviews: '398 Ratings',
    description: 'Swim with manta rays and walk with Komodo dragons in the world-famous Komodo National Park.',
    image: image
    // image: 'https://source.unsplash.com/featured/?yacht',
  },
  {
    title: 'Scuba Diving',
    price: '$175',
    rating: 9.2,
    reviews: '250 Ratings',
    description: 'We invite you to dive the coastline first discovered by Columbus by Dressel Divers.',
    image: image
    // image: 'https://source.unsplash.com/featured/?scubadiving',
  },
  {
    title: 'Horse Riding',
    price: '$190',
    rating: 9.5,
    reviews: '611 Ratings',
    description: 'Hop on a guided horseback ride around Constanza’s gorgeous valleys and villages.',
    image: image
    // image: 'https://source.unsplash.com/featured/?horsebackriding',
  },
];

const Tours = () => {
  return (
    <UserLayout>
        <div className="max-w-7xl mx-auto p-6">
      {/* Filter Buttons */}
      <div className="flex justify-center space-x-4 mb-8">
        <button className="bg-teal-500 text-white py-2 px-4 rounded-full">ALL</button>
        <button className="text-teal-500 py-2 px-4 rounded-full">ATV</button>
        <button className="text-teal-500 py-2 px-4 rounded-full">DIVING</button>
        <button className="text-teal-500 py-2 px-4 rounded-full">PARAGLIDING</button>
      </div>

      {/* Tours Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {toursData.map((tour, index) => (
          <div key={index} className="relative bg-white rounded-lg shadow-md overflow-hidden">
            {/* Tour Image */}
            <img
              className="w-full h-48 object-cover"
              src={tour.image}
              alt={tour.title}
            />
            {/* Price Tag */}
            <div className="absolute top-3 left-3 bg-white py-1 px-3 rounded-full text-black font-bold">
              {tour.price}
            </div>
            {/* Tour Details */}
            <div className="p-4">
              <h3 className="font-bold text-lg">{tour.title}</h3>
              <div className="flex items-center space-x-2 mt-2">
                <span className="text-xl font-bold">{tour.rating}</span>
                <span className="text-yellow-400">★ ★ ★ ★ ★</span>
                <span className="text-gray-500 text-sm">{tour.reviews}</span>
              </div>
              <p className="mt-2 text-gray-600">{tour.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
    </UserLayout>
  );
};

export default Tours;
