import UserLayout from "../../Components/Layout/UserLayout/UserLayout";
import FullWidthCarousel from "./FullWidthCarousel/FullWidthCarousel";
import PopularActivities from "./PopularActivities/PopularActivities";
import Review from "./Review/Review";

export default function Home(){
    return(
        <UserLayout>
            <FullWidthCarousel/>
            <PopularActivities/>
            <Review/>
        </UserLayout>
    )
}