import React, { useState } from 'react';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="p-8" style={{backgroundColor: "#2485B0"}}>
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo Section */}
        <div className="text-white text-2xl font-bold">
          <span className="tracking-widest">Expolre With Sawon</span>
        </div>

        {/* Desktop Menu */}
        <ul className="hidden md:flex space-x-8 text-white text-lg font-semibold">
          <li className="hover:text-gray-200 cursor-pointer"><a href='/'>HOME</a></li>
          <li className="hover:text-gray-200 cursor-pointer"><a href='/about'>ABOUT</a></li>
          <li className="hover:text-gray-200 cursor-pointer"><a href='/tours'>TOURS</a></li>
          {/* <li className="hover:text-gray-200 cursor-pointer"><a href='/blog'>BLOGS</a></li> */}
          <li className="hover:text-gray-200 cursor-pointer"><a href='/contact-us'>CONTACT</a></li>
        </ul>

        {/* Hamburger Icon for mobile */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <ul className="md:hidden flex flex-col space-y-4 text-white text-lg font-semibold p-4">
          <li className="hover:text-gray-200 cursor-pointer"><a href='/'>HOME</a></li>
          <li className="hover:text-gray-200 cursor-pointer"><a href='/about'>ABOUT</a></li>
          <li className="hover:text-gray-200 cursor-pointer"><a href='/tours'>TOURS</a></li>
          <li className="hover:text-gray-200 cursor-pointer"><a href='/contact-us'>CONTACT</a></li>
        </ul>
      )}
    </nav>
  );
};

export default Header;
