import React from 'react';
import UserLayout from '../../../Components/Layout/UserLayout/UserLayout';
import ContactUsForm from '../../../Components/UI/ContactUsForm/ContactUsForm';
import image from "../../../../asset/cycling.jpeg";
const TourDetails = () => {
  return (
    <UserLayout>
        <div className="max-w-6xl mx-auto p-6">
      {/* Image section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <img
          className="w-full h-64 object-cover rounded-lg"
          src={image}
          alt="Quad biking on sand dunes"
        />
        <div className="grid grid-cols-1 gap-4">
          <img
            className="w-full h-32 object-cover rounded-lg"
            src = {image}
            alt="Offroad bike"
          />
          <img
            className="w-full h-32 object-cover rounded-lg"
            src={image}
            alt="Dune buggy on sand dunes"
          />
        </div>
      </div>

      {/* Text section */}
      <div className="text-gray-700 space-y-4">
        <p>
          <strong>Boundless Boat Charters'</strong> family-friendly fishing excursions are <strong>limited to four passengers</strong>, meaning there's plenty of space for everyone to comfortably reel in their catch. The company runs morning and afternoon trips that last <strong>5 1/2 hours</strong> (perfect for first-timers or young anglers), nine-hour trips offshore or to Mexico's Coronado Islands, and a full-day (12-hour) deep-sea fishing outing.
        </p>
        <p>
          <strong>Coletta Sportfishing</strong> offers daily private charters on both its boats. Coletta is family-friendly and caters to all experience levels; the captain and crew will teach young and first-time anglers the basics of sportfishing. Outings range from <strong>half-day trips (5 1/2 hours)</strong> to full-day (12-hour) charters.
        </p>
      </div>
    </div>
    <h2>Book Your Trip Now</h2>
    <div className='max-w-6xl mx-auto p-6 flex grid grid-cols-1 md:grid-cols-2 justify-center p-10 gap-4 md:flex-row'>
    <ContactUsForm/>
    <div><img src={image} className='w-full h-64 object-cover rounded-lg'/></div>
    </div>
    </UserLayout>
  );
};

export default TourDetails;
